.modal-close {
    position: absolute;
    right: 2px;
    top: 6px;
    cursor: pointer;
}
.modal-close button {
    background: transparent;
    border: none;
}
.modal-close button i {
    font-size: 18px;
    color: #ffffff;
}
