.tile-wrap {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: space-around;
    margin: auto;
    max-width: 315px;
}

.tile {
    align-items: center;
    width: 100%;
    height: 80px;
    background-size: 100%;
    margin-bottom: 6px;
    border-radius: 6px;
    display: flex;
    border: 1px solid #dadada;
}

.tile:hover {
    cursor: pointer;
}

.tile span {
    width: 100%;
    //position: absolute;
    //top: 39%;
    justify-content: center;

    text-align: center;
    font-weight: bold;
}