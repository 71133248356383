.thepackage {
    margin-bottom: 20px;
    padding: 10px;
    border: 1px solid #dadada;
    border-radius: 4px;
}
.thepackage .title {
    margin-top: 0;
}
.extra-hours {
    margin-top: 20px;
}