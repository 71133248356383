
.buttonNavigation {
    color: #dadada;
    text-align: center;
    background: #d6023c;
    padding-top: 4px;
    padding-bottom: 4px;
    margin-left: 16px;
    margin-right: 16px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    position: relative;
}

@media all and (min-width: 600px) {
    .buttonNavigation {
        margin-left: 24px;
        margin-right: 24px;
    }
}

@media all and (min-width: 960px) {
    .buttonNavigation {
        margin-left: 32px;
        margin-right: 32px;
    }
}



.buttonNavigation .fa-circle {
    color: #ffffff;
    font-size: 26px;
}
.buttonNavigation .fas.fa-circle {
    color: #000000;
    font-size: 28px;
}


.buttonNavigation .navBtn {
    background-color: #000000;
    border-radius: 12px;
    cursor: pointer;
    border: 1px solid #000000;
    color: #ffffff;
    width: 24px;
    height: 24px;
    line-height: 18px;
    margin: 0 4px;
    text-align: center;
    padding: 0;
}
.buttonNavigation .navBtn:disabled {
    border: 1px solid #ffffff;
    background-color: transparent;
    color: #ffffff;
}


.buttonNavigation .navBtn span {
    font-size: 12px;
    font-family: sans-serif;
    text-align: center;
}