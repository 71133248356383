
.mainContainer {
  overflow: scroll;
}

.hidden {
  display: none !important;
}
.App {
  outline: 0;
  margin-top: 28px;
}

.slide {
  margin: auto;
  display: block;
  min-height: 98vh;
}

.slide header {
  color: #000000;
  padding-top: 8px;
}
.slide header h2 {
  font-size: 18px;
}

.slide-content-wrap {
  padding: 16px;
}

.formControl {
  width: 100%;
}

.MuiFormControl-root {
  display: flex;

}

.buttonsWrap {
  margin-top: 40px;
  margin-bottom: 20px;
  display: flex;
  align-content: space-between;
  justify-content: space-between;
}
.buttonsWrap .orange {
  background-color: rgb(228, 107, 11);
  color: #fff;
}
.buttonsWrap .orange:hover {
  color: #000;
}


.the-package-wrap {
  margin-bottom: 16px;
}

.the-package-wrap .chip {
  margin: 0 6px 6px 0;
}

.thepackage ul {
  margin-left: 0;
}



/* Autosuggest container */
.pac-container {
  z-index: 100000000;
}

.openModalWrapper {
  margin: 0;
  position: fixed;
  top: 150px;
  right: -14px;
  transform: rotate(90deg);
  transform-origin: bottom;
}

.openModal {
  background-color: rgb(228, 107, 11);
  color: #ffffff;
  outline: none;
  border: none;
  margin: 0;
  font-size: 18px;
  padding: 8px 12px;
  cursor: pointer;
}

.event-summary .totalCell {
  padding-right: 18px;
  position: relative;
}
.event-summary .totalCell i.fas {
  position: absolute;
  right: 0;
  top: 2px;
}


.configurator .MuiInputBase-root.Mui-disabled.grid-value {
  width: 40%;
  color: #000;
}
.configurator .grid-value input {
  text-align: center;
}
.configurator .fas {
  font-size: 1.2rem;
}


.App .MuiContainer-maxWidthMd {
  max-width: 860px;
}

.App .MuiPaper-rounded {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
