body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.mainContainer {
  overflow: scroll;
}

.hidden {
  display: none !important;
}
.App {
  outline: 0;
  margin-top: 28px;
}

.slide {
  margin: auto;
  display: block;
  min-height: 98vh;
}

.slide header {
  color: #000000;
  padding-top: 8px;
}
.slide header h2 {
  font-size: 18px;
}

.slide-content-wrap {
  padding: 16px;
}

.formControl {
  width: 100%;
}

.MuiFormControl-root {
  display: flex;

}

.buttonsWrap {
  margin-top: 40px;
  margin-bottom: 20px;
  display: flex;
  align-content: space-between;
  justify-content: space-between;
}
.buttonsWrap .orange {
  background-color: rgb(228, 107, 11);
  color: #fff;
}
.buttonsWrap .orange:hover {
  color: #000;
}


.the-package-wrap {
  margin-bottom: 16px;
}

.the-package-wrap .chip {
  margin: 0 6px 6px 0;
}

.thepackage ul {
  margin-left: 0;
}



/* Autosuggest container */
.pac-container {
  z-index: 100000000;
}

.openModalWrapper {
  margin: 0;
  position: fixed;
  top: 150px;
  right: -14px;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  -webkit-transform-origin: bottom;
          transform-origin: bottom;
}

.openModal {
  background-color: rgb(228, 107, 11);
  color: #ffffff;
  outline: none;
  border: none;
  margin: 0;
  font-size: 18px;
  padding: 8px 12px;
  cursor: pointer;
}

.event-summary .totalCell {
  padding-right: 18px;
  position: relative;
}
.event-summary .totalCell i.fas {
  position: absolute;
  right: 0;
  top: 2px;
}


.configurator .MuiInputBase-root.Mui-disabled.grid-value {
  width: 40%;
  color: #000;
}
.configurator .grid-value input {
  text-align: center;
}
.configurator .fas {
  font-size: 1.2rem;
}


.App .MuiContainer-maxWidthMd {
  max-width: 860px;
}

.App .MuiPaper-rounded {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.modal-close {
    position: absolute;
    right: 2px;
    top: 6px;
    cursor: pointer;
}
.modal-close button {
    background: transparent;
    border: none;
}
.modal-close button i {
    font-size: 18px;
    color: #ffffff;
}

.tile-wrap {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: space-around;
    margin: auto;
    max-width: 315px;
}

.tile {
    align-items: center;
    width: 100%;
    height: 80px;
    background-size: 100%;
    margin-bottom: 6px;
    border-radius: 6px;
    display: flex;
    border: 1px solid #dadada;
}

.tile:hover {
    cursor: pointer;
}

.tile span {
    width: 100%;
    //position: absolute;
    //top: 39%;
    justify-content: center;

    text-align: center;
    font-weight: bold;
}

.buttonNavigation {
    color: #dadada;
    text-align: center;
    background: #d6023c;
    padding-top: 4px;
    padding-bottom: 4px;
    margin-left: 16px;
    margin-right: 16px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    position: relative;
}

@media all and (min-width: 600px) {
    .buttonNavigation {
        margin-left: 24px;
        margin-right: 24px;
    }
}

@media all and (min-width: 960px) {
    .buttonNavigation {
        margin-left: 32px;
        margin-right: 32px;
    }
}



.buttonNavigation .fa-circle {
    color: #ffffff;
    font-size: 26px;
}
.buttonNavigation .fas.fa-circle {
    color: #000000;
    font-size: 28px;
}


.buttonNavigation .navBtn {
    background-color: #000000;
    border-radius: 12px;
    cursor: pointer;
    border: 1px solid #000000;
    color: #ffffff;
    width: 24px;
    height: 24px;
    line-height: 18px;
    margin: 0 4px;
    text-align: center;
    padding: 0;
}
.buttonNavigation .navBtn:disabled {
    border: 1px solid #ffffff;
    background-color: transparent;
    color: #ffffff;
}


.buttonNavigation .navBtn span {
    font-size: 12px;
    font-family: sans-serif;
    text-align: center;
}
.thepackage {
    margin-bottom: 20px;
    padding: 10px;
    border: 1px solid #dadada;
    border-radius: 4px;
}
.thepackage .title {
    margin-top: 0;
}
.extra-hours {
    margin-top: 20px;
}
